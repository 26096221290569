import React, { useRef } from "react";
import styled from "styled-components";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Section, SectionTitle, Container } from "../Section";
import ProductCardGridView from "../ProductCardGridView";
import { ArrowLeftIcon, ArrowRightIcon } from "../Icons/Arrow";

const SlickSlideCarousel = styled.div`
  margin: 0;
  &:after,
  &:before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 1;
    width: 60px;
    @media (min-width: 1200px) {
      width: 120px;
    }
  }
  &:after {
    left: 0;
    background: -moz-linear-gradient(
      left,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 0) 100%
    );
    background: -webkit-linear-gradient(
      left,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 0) 100%
    );
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 0) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00ffffff',GradientType=1 );
  }
  &:before {
    right: 0;
    background: -moz-linear-gradient(
      left,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 100%
    );
    background: -webkit-linear-gradient(
      left,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 100%
    );
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=1 );
  }

  .slick-arrow {
    position: absolute;
    z-index: 10;
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-color: black;
    svg {
      fill: #fff;
    }
    &:hover,
    &:focus {
      background-color: #fc0002;
    }
    &.slick-disabled {
      background-color: #eeeeee;
      cursor: default;
      svg {
        fill: #999;
      }
    }

    &.slick-prev {
      left: 2px;
      border-radius: 50%;
    }
    &.slick-next {
      right: 2px;
      border-radius: 50%;
    }
    &:before {
      display: none;
    }
  }
`;
const SlickItem = styled.div`
  outline: none;
  .card {
    margin: 0 10px 25px 10px;
    @media (min-width: 1200px) {
      margin: 0 15px 25px 15px;
    }
    @media (min-width: 1600px) {
      margin: 0 25px 25px 25px;
    }
  }
`;

function CircleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "flex" }}
      onClick={onClick}
      aria-hidden="true"
    >
      <ArrowRightIcon />
    </div>
  );
}

function CirclePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "flex" }}
      onClick={onClick}
      aria-hidden="true"
    >
      <ArrowLeftIcon />
    </div>
  );
}

const ProductCarousel = ({
  data,
  title = "Our Top-Selling Metal Buildings",
}) => {
  const settings = {
    slidesToShow: 3,
    slidesToScroll: 1,
    speed: 500,
    nextArrow: <CircleNextArrow />,
    prevArrow: <CirclePrevArrow />,
    centerMode: false,
    centerPadding: "50px",
    infinite: false,
    focusOnSelect: true,
    arrows: true,
    dots: false,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1,
          centerPadding: "160px",
        },
      },
      {
        breakpoint: 567,
        settings: {
          slidesToShow: 1,
          centerPadding: "60px",
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          centerPadding: "20px",
        },
      },
    ],
  };

  const sliderRef = useRef(null);
  return (
    <Section
      ept="60px"
      epb="60px"
      xpt="40px"
      xpb="40px"
      pt="30px"
      pb="30px"
      bgColor="#fff"
      className="section-product"
    >
      <Container className="container">
        <SectionTitle textAlign="center" mb="40px">
          {title}
        </SectionTitle>
      </Container>
      <SlickSlideCarousel>
        <Slider {...settings} ref={sliderRef}>
          {data.map((item, i) => (
            <SlickItem key={i}>
              <ProductCardGridView product={item.node} />
            </SlickItem>
          ))}
        </Slider>
      </SlickSlideCarousel>
    </Section>
  );
};

export default ProductCarousel;
